import React from "react";

import DashBoardImage from "../../assets/image/home-3/png/lens-ss1.png";
import snapcode from "../../assets/image/home-3/png/snapcode.svg"
import YellowShape from "../../assets/image/home-3/png/yellow-shape.png";
import GreenShape from "../../assets/image/home-3/png/output-onlinepngtools.png";
import { Link } from "gatsby";

const Hero = ({className,...rest}) => {
    return (
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div
                className="text-center mb-11 mb-lg-24"
                data-aos="zoom-in"
                data-aos-delay={500}
              >
                <h1 hegiht className="font-size-11 mb-7">
                  View Food Menus On Snap
                </h1>
                <p className="font-size-7 px-lg-5 mb-0">
                  Get a quick glance at food options.
                </p>
                <div className="btn-group d-inline-block mt-10">
                  <a href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=311fcefd0ece4f7f86ec1672890481a4&metadata=01">
                    <button className="btn btn btn-golden-yellow header-btn-10 rounded-10">
                    Use In Snap
                    </button>
                  </a>
                  <p className="d-block mt-6 font-size-3 mb-0 text-bali-gray letter-spacing-reset">
                    No credit card required ever


                  </p>
                  <br></br>
                  <a href="https://forms.gle/VwWkSsJVtVwHUoD99">
                    <button className="btn btn btn-golden-yellow header-btn-10 rounded-10">
                    Contribute Images
                    </button>
                  </a>

                </div>
              </div>
            </div>
          </div>
          {/* Hero Image Group */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-11">
              <div className="l3-hero-image-group w-100">
                {/* Image One */}
                <div
                  className="img-1 shadow-13"
                  data-aos="fade-up"
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <img width="200px" src={snapcode} alt="" />
                  {/* Image Two */}
                  <div className="img-2">
                    <img
                      src={GreenShape}
                      alt=""
                      data-aos="fade-right"
                      data-aos-delay={700}
                      data-aos-once="true"
                    />
                  </div>
                  {/* Image Three */}
                  <div className="img-3">
                    <img
                      src={GreenShape}
                      alt=""
                      data-aos="fade-left"
                      data-aos-delay={900}
                      data-aos-once="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Image Group */}
        </div>
      </div>
    );
}


export default Hero;